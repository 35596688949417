#showroomBanner {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 200px;
    width: 100vw;
    background-color: #253f8e;
    margin-top: 65px;
}

#showroomBanner p {
    margin: 0;
    color: white;
    font-family: 'NewFrench';
    font-size: 80px;
    text-align: center;
}

#showroomBanner .showroomBannerGradientBorder {
    position: absolute;
    bottom: 0;
    height: 10px;
    background: linear-gradient(45deg, #6F3A8F, #F26B4B);
    width: 100%;
}

@media screen and (max-width: 680px) {
    #showroomBanner p {
        font-size: 60px;
    }
}