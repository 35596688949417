#mire {
    background: linear-gradient(45deg, #6F3A8F, #F26B4B);
    width: 100%;
    margin: 0;
    padding: 6px;
    border-radius: 20px;
    min-width: 600px;
}

#mire .mireContainer {
    background-color: white;
    border-radius: 14px;
    overflow: hidden;
}

#mire .mireContainer .mireTitle {
    font-family: 'NewFrench';
    font-size: 40px;
    margin-top: 20px;
    margin-bottom: 0;
    text-align: center;
    color: #253f8e;
}

#mire .mireContainer .WithStyles-DialogActions--root-186 {
    border-top: 0;
}

@media screen and (max-width: 700px) {
    #mire {
        min-width: 250px;
        width: 90%;
    }
}